


















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onMounted, ref, toRefs } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormFieldText, useFormObserver, cleanFieldValue, useForm } from '@/utils/forms.utils';
// Import components -------------------------------------------------------------------------------
import FormFieldText from '@/components/forms/FormFieldText.vue';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { WorkspaceEntity } from '@/plugins/podocore/repositories/workspaces.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'WorkspaceInviteForm',
  props: {
    workspace: {
      type: Object as PropType<WorkspaceEntity>,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FormFieldText
  },
  setup(properties, { emit }) {
    const rObserver = useFormObserver();

    const email = useFormFieldText<string>({
      key: 'email',
      value: '',
      rules: {
        required: true,
        email: true
      }
    });

    const { submit } = useForm(
      {
        email
      },
      rObserver,
      () => {
        emit('submit', getFields());
      }
    );

    function getFields() {
      return {
        email: cleanFieldValue(email)
      };
    }

    return {
      // References
      rObserver,
      // Form fields
      email,
      // Methods
      submit
    };
  }
});
