import { render, staticRenderFns } from "./SettingsWorkspaceMembers.vue?vue&type=template&id=feca8356&scoped=true&"
import script from "./SettingsWorkspaceMembers.vue?vue&type=script&lang=ts&"
export * from "./SettingsWorkspaceMembers.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsWorkspaceMembers.vue?vue&type=style&index=0&id=feca8356&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feca8356",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_a266eb3209234f15fc02fe57f54d2cc5/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VCard,VCardText,VChip,VDivider,VHover,VList,VListItem,VListItemAction,VListItemActionText,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu})
