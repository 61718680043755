






































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { capitalize } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import DialogWorkspaceMemberInvite from '@/components/dialogs/DialogWorkspaceMemberInvite.vue';
// Import types ------------------------------------------------------------------------------------
import {
  AbilityAction,
  AbilitySubject
} from '@digitsole/blackburn-entities/dist/entities/ability/types/ability.enums';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsWorkspaceMembers',
  components: {
    DialogWorkspaceMemberInvite
  },
  props: {
    workspacesService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const aclModule = usePodocoreModule('acl');
    const requestModule = usePodocoreModule('request');
    const doctorService = usePodocoreModuleService('doctor');

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const currentWorkspace = computed(() => {
      return (properties.workspacesService.state.value as any).context.current;
    });

    const doctorRole = computed(() => {
      return currentWorkspace.value.members.find((member: any) => member.cuid === doctor.value.cuid).role;
    });

    const remainingFreeUserSlots = computed(() => {
      return (
        aclModule.reactiveBuild.relevantRuleFor(AbilityAction.Invite, AbilitySubject.Workspace)?.conditions
          ?.remainingFreeUserSlots ?? 0
      );
    });

    const busModule = usePodocoreModule('bus');

    function openInviteDialog() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'workspace-invite'
        })
      );
    }

    const deleteMemberRequest: any = ref(null);

    function deleteMember(memberCuid: string, isInvited: boolean) {
      if (isPending.value) return;

      deleteMemberRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/workspaces/${currentWorkspace.value.cuid}/members/${memberCuid}?isInvited=${isInvited}`,
        {
          axios: {
            method: 'DELETE'
          }
        }
      );
      deleteMemberRequest.value?.request();
    }

    const isPending = computed(() => deleteMemberRequest.value?.isPending.value);

    const isSuccess = computed(() => {
      return !deleteMemberRequest.value?.error && !!deleteMemberRequest.value?.data;
    });

    const workspaceInviteMembersRequest = computed(() =>
      requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/workspaces/${currentWorkspace.value.cuid}/members/invite`,
        {
          axios: {
            method: 'POST'
          }
        }
      )
    );

    function inviteWorkspaceMembers(cuid: string) {
      const redirect_uri = new URL(window.location.origin);

      redirect_uri.pathname = '/join-workspace';

      workspaceInviteMembersRequest.value.request({
        axios: {
          method: 'POST',
          data: {
            doctorCuid: cuid,
            redirectUrl: redirect_uri.href
          }
        }
      });
    }

    watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.workspaceMembersDeleted({
            workspaceCuid: currentWorkspace.value.cuid
          })
        );
      }
    });

    return {
      // Values
      doctor,
      doctorRole,
      currentWorkspace,
      remainingFreeUserSlots,
      isPending,
      // Functions
      openInviteDialog,
      capitalize,
      deleteMember,
      inviteWorkspaceMembers
    };
  }
});
