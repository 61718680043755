
































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, watch } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useForm, useFormFieldAutoComplete, useFormObserver } from '@/utils/forms.utils';
import { useUnit, TUnit } from '@/utils/unit.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '@/components/composite/CompositeCard.vue';
import FormFieldSelect from '@/components/forms/FormFieldSelect.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// Import types ------------------------------------------------------------------------------------
import { EUnit } from '@/plugins/podocore/repositories/doctor.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsUnits',
  components: { AlertError, CompositeCard, FormFieldSelect },
  setup() {
    const rObserver = useFormObserver();

    const { supportedUnits } = useUnit();

    const { trackSuccess, trackFailure } = useAnalytics();

    const doctorService = usePodocoreModuleService('doctor');

    const doctorConfigUnit = computed(() => {
      return (doctorService.state.value as any).context.doctor.config.unit;
    });

    const unit = useFormFieldAutoComplete<EUnit, TUnit>({
      key: 'unit',
      value: doctorConfigUnit.value ?? EUnit.Metric,
      items: supportedUnits,
      rules: {
        required: true
      }
    });

    // Watch "unit" and execute "submit" when a mutation occurs
    watch(
      () => unit.value,
      () => {
        if (doctorConfigUnit.value !== unit.value) {
          submit();
        }
      }
    );

    watch(doctorService.state, (currentState, previousState) => {
      // If an error occurs, replace "lang" by previous value & send failure event
      if (
        previousState.matches({ patching: { config: 'processing' } }) &&
        currentState.matches({
          patching: { config: 'failure' }
        })
      ) {
        unit.value = doctorConfigUnit.value;
        trackFailure('Settings/UnitSystem', 'Unit save failed');
      }
      // If success, send success event
      if (
        previousState.matches({ patching: { config: 'processing' } }) &&
        currentState.matches({
          patching: { config: 'success' }
        })
      ) {
        trackSuccess('Settings/UnitSystem', supportedUnits.find((x: TUnit) => x.value === unit.value)!.text);
      }
    });

    // Form manager
    const { submit } = useForm({ unit }, rObserver, (fields) => {
      doctorService.send({
        type: 'PATCH_CONFIG',
        data: {
          unit: fields.unit.value
        }
      } as any);
    });

    const isSucceeded = computed(() =>
      [{ patching: { config: 'success' } }].some((element) => doctorService.state.value.matches(element))
    );

    const isErrored = computed(() =>
      [{ patching: { config: 'failure' } }].some((element) => doctorService.state.value.matches(element))
    );

    const isDisabled = computed(
      () =>
        ![{ patching: { config: 'success' } }, { patching: { config: 'failure' } }, 'success'].some(
          (element) => doctorService.state.value.matches(element)
        )
    );

    const isLoading = computed(() =>
      [{ patching: { config: 'processing' } }].some((element) => doctorService.state.value.matches(element))
    );

    return {
      // Form observer
      rObserver,
      // Services
      // Values
      supportedUnits,
      // Form fields
      unit,
      // Flags
      isSucceeded,
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
