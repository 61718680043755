








































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watch, toRef } from '@vue/composition-api';
import { capitalize, get } from 'lodash';
import { phone } from 'phone';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
import {
  cleanFieldValue,
  useForm,
  useFormFieldAutoComplete,
  useFormFieldText,
  useFormObserver
} from '@/utils/forms.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '@/components/composite/CompositeCard.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
import { ICountry } from '@digitsole/blackburn-iso-3166-dial';
import { getCountries } from '@/utils/i18n.utils';
import FormFieldPhone from '@/components/forms/FormFieldPhone.vue';
import FormFieldAutoComplete from '@/components/forms/FormFieldAutoComplete.vue';
import SettingsWorkspaceMembers from '@/components/settings/SettingsWorkspaceMembers.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsWorkspace',
  components: {
    CompositeCard,
    AlertError,
    FormFieldText,
    FormFieldPhone,
    FormFieldAutoComplete,
    SettingsWorkspaceMembers
  },
  props: {
    workspacesService: {
      type: Object,
      required: true
    }
  },
  setup(properties, { root }) {
    const busModule = usePodocoreModule('bus');

    const aclService = usePodocoreModuleService('acl');

    const aclFeatures = computed(() => {
      return (aclService.state.value as any).context.features;
    });

    const rObserver = useFormObserver();

    const currentWorkspace = computed(() => {
      return (properties.workspacesService.state.value as any).context.current;
    });

    const { trackSuccess, trackFailure } = useAnalytics();

    const isLocked = ref(true);

    const defaultFormFieldsValues = computed(() => {
      const _phone = phone(currentWorkspace.value.phone, { validateMobilePrefix: false });
      return {
        name: currentWorkspace.value.name as string,
        address: {
          street: currentWorkspace.value.address?.street as string,
          zipCode: currentWorkspace.value.address?.zipCode as string,
          city: currentWorkspace.value.address?.city as string,
          country: currentWorkspace.value.address?.country as string,
          state: currentWorkspace.value.address?.state as string
        },
        color: currentWorkspace.value.color as string,
        phone: {
          phoneDial: _phone.isValid ? _phone.countryCode.replace('+', '') : '1',
          phoneValue: _phone.isValid ? _phone.phoneNumber.replace(_phone.countryCode, '') : ''
        }
      };
    });

    const name = useFormFieldText<string>({
      key: 'name',
      value: defaultFormFieldsValues.value.name ?? '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });
    const address = {
      street: useFormFieldText<string>({
        key: 'address.street',
        value: defaultFormFieldsValues.value.address.street ?? '',
        rules: {}
      }),
      zipCode: useFormFieldText<string>({
        key: 'address.zip-code',
        value: defaultFormFieldsValues.value.address.zipCode ?? '',
        rules: {}
      }),
      city: useFormFieldText<string>({
        key: 'address.city',
        value: defaultFormFieldsValues.value.address.city ?? '',
        rules: {}
      }),
      state: useFormFieldText<string>({
        key: 'address.state',
        value: defaultFormFieldsValues.value.address.state ?? '',
        rules: {}
      }),
      country: useFormFieldAutoComplete<string, ICountry>({
        key: 'address.country',
        value: defaultFormFieldsValues.value.address.country ?? '',
        items: getCountries(),
        rules: {}
      })
    };
    const phoneDial = useFormFieldText<string>({
      key: 'phone.dial',
      value: defaultFormFieldsValues.value.phone.phoneDial ?? '1',
      rules: {
        required: true
      }
    });
    const phoneValue = useFormFieldText<string>({
      key: 'phone.value',
      value: defaultFormFieldsValues.value.phone.phoneValue ?? '',
      rules: {
        phone: toRef(phoneDial, 'value')
      }
    });
    const color = ref(defaultFormFieldsValues.value.color ?? '#000000');

    function getFields() {
      return {
        name: cleanFieldValue(name),
        address: {
          street: cleanFieldValue(address.street),
          zipCode: cleanFieldValue(address.zipCode),
          city: cleanFieldValue(address.city),
          state: cleanFieldValue(address.state),
          // TODO : fix typing
          country: cleanFieldValue(address.country as never)
        },
        color: cleanFieldValue(color),
        phone:
          cleanFieldValue(phoneDial) && cleanFieldValue(phoneValue)
            ? '+' + (((cleanFieldValue(phoneDial) as any) + cleanFieldValue(phoneValue)) as any)
            : null
      };
    }

    function unlock() {
      if (root.$can('update', 'workspace')) isLocked.value = false;
    }
    function lock() {
      isLocked.value = true;

      // Reset form fields values
      name.value = defaultFormFieldsValues.value.name;
      address.street.value = defaultFormFieldsValues.value.address?.street;
      address.zipCode.value = defaultFormFieldsValues.value.address?.zipCode;
      address.city.value = defaultFormFieldsValues.value.address?.city;
      address.state.value = defaultFormFieldsValues.value.address?.state;
      address.country.value = defaultFormFieldsValues.value.address?.country;
      color.value = defaultFormFieldsValues.value.color;

      if (defaultFormFieldsValues.value.phone.phoneValue) {
        phoneDial.value = defaultFormFieldsValues.value.phone.phoneDial;
        phoneValue.value = defaultFormFieldsValues.value.phone.phoneValue;
      } else {
        phoneDial.value = '1';
      }
    }

    // Lock when form submission succeeded
    watch(properties.workspacesService.state, (currentState: any, previousState: any) => {
      if (
        previousState.matches({ patchingCurrent: 'processing' }) &&
        currentState.matches({ patchingCurrent: 'success' })
      ) {
        lock();
      }
    });

    // Form manager
    const { submit } = useForm({ name, address, color }, rObserver, (fields) => {
      properties.workspacesService.send({
        type: 'PATCH_CURRENT',
        data: getFields()
      } as any);
    });

    const isSucceeded = computed(() =>
      [{ patchingCurrent: 'success' }].some((element) =>
        properties.workspacesService.state.value.matches(element)
      )
    );
    watch(isSucceeded, (value) => {
      if (value)
        trackSuccess('Settings/Workspace', {
          country: address.country.value,
          city: address.city.value,
          color: color.value
        });
    });

    const isErrored = computed(() =>
      [{ patchingCurrent: 'failure' }].some((element) =>
        properties.workspacesService.state.value.matches(element)
      )
    );
    watch(isErrored, (value) => {
      if (value) trackFailure('Settings/Workspace', 'Workspace save failed');
    });

    const isDisabled = computed(
      () =>
        !['success', { patchingCurrent: 'failure' }, { patchingCurrent: 'success' }].some((element) =>
          properties.workspacesService.state.value.matches(element)
        ) || isLocked.value
    );

    const isLoading = computed(() =>
      [{ patchingCurrent: 'processing' }].some((element) =>
        properties.workspacesService.state.value.matches(element)
      )
    );

    watch(properties.workspacesService.state, (currentState: any, previousState: any) => {
      // TODO : using a bug to detect workspace selection, must be updated later
      if (currentState.matches('success') && previousState.matches('success')) {
        lock();
      }
    });

    busModule.useEventSubscriber(busModule.events.workspaceMembersInvited, (event) => {
      properties.workspacesService.send({
        type: 'FETCH',
        data: {
          workspaceCuid: event.payload.workspaceCuid
        }
      } as any);

      aclService.send({
        type: 'CHECK'
      });
    });

    busModule.useEventSubscriber(busModule.events.workspaceMembersDeleted, (event) => {
      properties.workspacesService.send({
        type: 'FETCH',
        data: {
          workspaceCuid: event.payload.workspaceCuid
        }
      } as any);

      aclService.send({
        type: 'CHECK'
      });
    });

    return {
      // Form observer
      rObserver,
      // Form fields
      name,
      address,
      phoneDial,
      phoneValue,
      color,
      // Values
      currentWorkspace,
      aclFeatures,
      // Actions
      unlock,
      lock,
      submit,
      // Methods
      capitalize,
      get,
      // Flags
      isLocked,
      isSucceeded,
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
