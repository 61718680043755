



























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import Page from '@/components/Page.vue';
import PageRow from '@/components/PageRow.vue';
import SettingsLanguage from '@/components/settings/SettingsLanguage.vue';
import SettingsNotifications from '@/components/settings/SettingsNotifications.vue';
import SettingsUnits from '@/components/settings/SettingsUnits.vue';
import SettingsWorkspace from '@/components/settings/SettingsWorkspace.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageSettings',
  components: {
    SettingsUnits,
    Page,
    PageRow,
    SettingsLanguage,
    SettingsNotifications,
    SettingsWorkspace
  },
  setup() {
    const workspacesService = usePodocoreModuleService('workspaces');

    return {
      workspacesService
    };
  }
});
