import { render, staticRenderFns } from "./SettingsWorkspace.vue?vue&type=template&id=7c065f52&scoped=true&"
import script from "./SettingsWorkspace.vue?vue&type=script&lang=ts&"
export * from "./SettingsWorkspace.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsWorkspace.vue?vue&type=style&index=0&id=7c065f52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c065f52",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_a266eb3209234f15fc02fe57f54d2cc5/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VChip,VCol,VColorPicker,VDivider,VForm,VList,VListItem,VListItemActionText,VListItemContent,VListItemTitle,VMenu,VRow})
